<template>
  <section class="dtc-main-section mt-2 mr-2">
    <FocusDeptDataEdit @updateFocusDeptData="getData"></FocusDeptDataEdit>
    <ContractRepayRecord></ContractRepayRecord>
    <ProgressRecord></ProgressRecord>
    <FeeClaim></FeeClaim>
    <section class="search-block">
      <h5 class="big-title">重點科別培育公費生基本資料維護</h5>
      <div class="dtc-search1">
        <b-input-group prepend="姓名">
          <b-input
            v-model.trim="searchName"
            placeholder="請輸入姓名"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model.trim="searchId"
            placeholder="請輸入身分證字號"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="出生日期">
          <div class="search-birthday">
            <b-input
              v-model.trim="searchBirthday.year"
              placeholder="民國年"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="searchBirthday.month"
              placeholder="月"
              @keypress="isNumber($event)"
              ref="searchBirthdayMonth"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="searchBirthday.day"
              placeholder="日"
              @keypress="isNumber($event)"
              ref="searchBirthdayDay"
            ></b-input>
          </div>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-select
            :options="genderObject"
            v-model="searchGender"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="success" @click="getExcel" v-if="!loadingExcel"
          >匯出清單</b-button
        >
        <b-button variant="success" disabled v-else>
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
        <b-button variant="success"  @click="$refs.file.click()">上傳名單</b-button>
        <input  ref="file" type="file" accept=".xlsx, .xls" @change="fileChange" hidden />
        <b-button variant="outline-primary" @click="downloadfile">範例下載</b-button>
      </div>
      <div class="dtc-search">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
            :disabled="healthCodeCheck"
          ></b-select>
        </b-input-group>
        <!--b-input-group prepend="科別">
          <b-select
            :options="majorObject"
            v-model="searchMajor"
            @change="searchDb"
          ></b-select>
        </b-input-group-->
        <b-input-group prepend="入學年">
          <b-input
            v-model.trim="searchEntryYear"
            placeholder="民國年"
            @keypress="isNumber($event)"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="畢業年">
          <b-input
            v-model.trim="searchEndYear"
            placeholder="民國年"
            @keypress="isNumber($event)"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="服務情形">
          <b-select
            :options="localStatusObject"
            v-model="searchStatus"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="服務現況">
          <b-select
            :options="serviceOption"
            v-model="searchServiceNow"
            @change="searchDb"
          ></b-select>
        </b-input-group>
      </div>
      <!--div class="dtc-search">
        <b-input-group prepend="籍別">
          <b-select
            :options="countryObject"
            v-model="searchCategory"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="籍貫">
          <b-select
            :options="hometownObject"
            v-model="searchHometown"
            @change="searchDb"
          ></b-select>
        </b-input-group>
      </div-->
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color: #39312e"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div
        style="cursor: pointer; padding-top: 3px"
        @click="editFocusDeptData(item)"
      >
      <img src="../../pictures/SearchIICCOONN.svg" alt="Search Icon" style="height: 25px; width: 45px;"/>
        <!--b-icon icon="pencil" font-scale="1.8"></b-icon-->
      </div>
      <!--div
        style="
          cursor: pointer;
          font-size: 1.47rem;
          color: #060656;
          padding-top: 0px;
        "
        @click="openContract(item)"
      >
        <i class="fas fa-paste"></i>
      </div-->
      <div :title="item.name">
        {{ item.name }}
      </div>
      <div :title="item.id">
        {{ item.id }}
      </div>
      <div :title="item.school">
        {{ item.school }}
      </div>
      <div :title="item.entryYear">
        {{ item.entryYear }}
      </div>
      <div :title="item.status">
        {{ item.status }}
      </div>
      <div :title="item.servicenow">
        {{ item.servicenow }}
      </div>
      <div :title="item.servicesys">
        {{ item.servicesys }}
      </div>
      <div
        style="cursor: pointer; font-size: 1.6rem; padding-top: 0px"
        @click="openProgressRecord(item)"
      >
        <i class="far fa-address-card"></i>
      </div>
      <div
        style="cursor: pointer; font-size: 1.47rem; padding-top: 0px"
        @click="openFeeClaim(item)"
      >
        <i class="fas fa-money-check-alt"></i>
      </div>
      <div
        style="
          cursor: pointer;
          font-size: 1.47rem;
          color: rgb(223 14 54);
          padding-top: 0px;
        "
        @click="openRepayRecord(item)"
        :style="item.status != '履約終止' ? 'color:rgb(165 11 40);' : ''"
      >
        <i class="fas fa-exclamation-triangle"></i>
      </div>
      <!--div :title="item.gender">
        {{ item.gender }}
      </div-->
      <!--div :title="item.birthday">
        {{ item.birthday }}
      </div-->
      <!--div :title="item.cat">
        {{ item.cat }}
      </div-->
      <!--div :title="item.graduateYear">
        {{ item.graduateYear }}
      </div-->
      <!--div :title="item.domicile">
        {{ item.domicile }}
      </div>
      <div :title="item.hometown">
        {{ item.hometown }}
      </div-->
      <!--div :title="item.contactAddress" class="note">
        {{ item.contactAddress }}
      </div-->
      <!--div :title="item.homeAddress" class="note">
        {{ item.homeAddress }}
      </div-->
      <!--div :title="item.telphone" class="note">
        {{ item.telphone }}
      </div-->
      <!--div :title="item.mobilephone">
        {{ item.mobilephone }}
      </div-->
      <!--div :title="item.email">
        {{ item.email }}
      </div-->
      <!--div :title="item.originRemark" class="note">
        {{ item.remark }}
      </div-->
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import FocusDeptDataEdit from "./FocusDeptDataEdit";
import ContractRepayRecord from "./ContractRepayRecord";
import ProgressRecord from "./ProgressRecord";
import FeeClaim from "./FeeClaim";

import {
  genderObject,
  countriesObject,
  localStatusObject,
  allServiceObject,
  domainObject,
} from "@/constant.js";

const headers = [
  "編輯檢視",
  "姓名",
  "身分證字號",
  "培育學校",
  { name: "入學年", key: "StartYear", sortDesc: null },
  "服務情形",
  "服務現況",
  "服務系統",
  "服務歷程",
  "請領費用",
  "違約",
  /*"姓名",
  "身分證字號",
  "性別",
  "出生日期",
  "培育學校",
  //"科別",
  { name: "入學年", key: "StartYear", sortDesc: null },
  { name: "畢業年", key: "EndYear", sortDesc: null },
  "服務系統",
  //"籍別",
  //"籍貫",
  "通訊地址",
  "戶籍地址",
  "市話",
  "行動電話",
  "E-mail",
  "備註",*/
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });
// schoolObject.unshift({ value: null, text: "全部" });
// majorObject.unshift({ value: null, text: "全部" });
// countryObject.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      filename: "",
      fakeData: [],
      countryObject: [],
      genderObject,
      schoolObject: [],
      majorObject: [],
      countriesObject,
      localStatusObject,
      allServiceObject,
      hometownObject: [],
      serviceOption: [],
      searchName: "",
      searchId: "",
      searchGender: null,
      searchBirthday: {
        year: "",
        month: "",
        day: "",
      },
      searchSchool: null,
      searchEntryYear: null,
      searchEndYear: null,
      searchMajor: null,
      searchCategory: null,
      searchHometown: null,
      searchStatus: null,
      searchServiceNow: null,

      loadingExcel: false,
      domainObject,

      allHealthOption: [],
    };
  },
  components: {
    FocusDeptDataEdit,
    ContractRepayRecord,
    ProgressRecord,
    FeeClaim,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
    isHealthCenterCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [...roleHealthBureauArr, ...roleHealthCenter];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {
    async getExcel() {
      const top = 500000;
      const skip = 0;
      const obj = { top, skip };
      let qs = buildQuery(obj);
      qs = `${qs}&category=T`;
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
        // qs = this.$equalFilter("SchoolCode", qs, school);
      }
      let bQs = false;
      if (this.searchName) {
        qs = this.$equalSubstringofFilter("Name", qs, this.searchName);
        bQs = true;
      }

      if (this.searchId) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
        } else {
          qs = this.$equalSubstringofFilter("Identifier", qs, this.searchId);
        }
        bQs = true;
      }
      if (this.searchGender) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Gender", qs, this.searchGender);
        } else {
          qs = this.$equalSubstringofFilter("Gender", qs, this.searchGender);
        }
        bQs = true;
      }
      if (
        Boolean(this.searchBirthday.year) &&
        Boolean(this.searchBirthday.month) &&
        Boolean(this.searchBirthday.day)
      ) {
        const date = `${this.searchBirthday.year}-${this.searchBirthday.month}-${this.searchBirthday.day}`;
        const startDate = moment(this.$usDate(date))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const endDate = this.$usDate(date);
        if (bQs) {
          qs = this.$appendTimeFilter("BirthDate", qs, startDate, endDate);
        } else {
          qs = this.$equalTimeFilter("BirthDate", qs, startDate, endDate);
        }
        bQs = true;
      }
      if (this.searchSchool) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "SchoolCode",
            qs,
            this.searchSchool
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "SchoolCode",
            qs,
            this.searchSchool
          );
        }
        bQs = true;
      }

      if (this.searchEntryYear) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "StartYear",
            qs,
            this.searchEntryYear
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "StartYear",
            qs,
            this.searchEntryYear
          );
        }
        bQs = true;
      }

      if (this.searchEndYear) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("EndYear", qs, this.searchEndYear);
        } else {
          qs = this.$equalSubstringofFilter("EndYear", qs, this.searchEndYear);
        }
        bQs = true;
      }
      if (this.searchMajor) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("MajorName", qs, this.searchMajor);
        } else {
          qs = this.$equalSubstringofFilter("MajorName", qs, this.searchMajor);
        }
        bQs = true;
      }
      if (this.searchCategory) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "Country",
            qs,
            this.searchCategory
          );
        } else {
          qs = this.$equalSubstringofFilter("Country", qs, this.searchCategory);
        }
        bQs = true;
      }

      if (this.searchHometown) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("County", qs, this.searchHometown);
        } else {
          qs = this.$equalSubstringofFilter("County", qs, this.searchHometown);
        }
        bQs = true;
      }
      if (this.searchStatus) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "ServiceItem",
            qs,
            this.searchStatus
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "ServiceItem",
            qs,
            this.searchStatus
          );
        }
        bQs = true;
      }
      if (this.searchServiceNow) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "ServiceDetail",
            qs,
            this.searchServiceNow
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "ServiceDetail",
            qs,
            this.searchServiceNow
          );
        }
        bQs = true;
      }
      const url = `StudentInfo/GetExportResult2${qs}`;
      try {
        this.loadingExcel = true;
        const res = await window.axios.get(url);
        this.loadingExcel = false;
        const excelUrl = `${this.domainObject.platformOriginal}${res}`;
        window.open(excelUrl);
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async fileChange(e) {
    let form = new FormData();
    form.append("input",  e.target.files[0])
     e.target.value = '';
      try {
        const map = await window.axios.post(
          "StudentInfo/ImportStudentT",
          form
        );
        this.$bvToast.toast(`新增成功!!`, {
          title: "上傳檔案",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData()
      } catch (e) {
        this.$bvToast.toast("新增失敗!! " + e, {
          title: "上傳檔案",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    downloadfile(evt) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href ="匯入範例.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    editFocusDeptData(item) {
      store.editItem = { ...item };
      this.$bvModal.show("FocusDepartmentDataEdit");
    },
    openProgressRecord(item) {
      // store.editItem = { ...item };
      store.studentProgressInfo = { ...item };
      this.$bvModal.show("studentProgress");
    },
    openFeeClaim(item) {
      store.editItem = { ...item };
      this.$bvModal.show("feeClaimDetail");
    },
    openContract(item) {
      store.editItem = { ...item };
      store.nowIndex = 31;
      this.$router.push("contractview");
    },
    openRepayRecord(item) {
      if (item.status != "履約終止") {
        return;
      }
      store.editItem = { ...item };
      this.$bvModal.show("contractrepayrecord");
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchName = "";
      this.searchId = "";
      this.searchGender = null;
      this.searchBirthday = {
        year: "",
        month: "",
        day: "",
      };
      this.searchSchool = null;
      this.searchEntryYear = null;
      this.searchEndYear = null;
      this.searchMajor = null;
      this.searchCategory = null;
      this.searchHometown = null;
      this.searchStatus = null;
      this.searchServiceNow = null;
      this.getData();
    },
    async getData() {
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }
      if (this.isHealthCenterCheck) {
        const centerCode = sessionStorage.getItem("darcok").replace(/\"/g, "");
        const centerPlace = this.allHealthOption.find(
          (s) => s.No == centerCode
        ).Name;
        let placeChinese = centerPlace.substring(0, 3);

        if (placeChinese == "臺東縣" || placeChinese == "台東縣") {
          this.hometownObject = this.hometownObject.filter(
            (s) =>
              s.text == "臺東縣" ||
              s.text.includes("蘭嶼") ||
              s.text.includes("綠島")
          );

          this.searchHometown = Boolean(this.searchHometown)
            ? this.searchHometown
            : this.hometownObject[0].value;
        } else if (placeChinese.includes("屏東")) {
          this.hometownObject = this.hometownObject.filter(
            (s) => s.text.includes("屏東") || s.text.includes("琉球")
          );

          this.searchHometown = Boolean(this.searchHometown)
            ? this.searchHometown
            : this.hometownObject[0].value;
        } else {
          this.hometownObject = this.hometownObject.filter(
            (s) => s.text == placeChinese
          );
          this.searchHometown = this.hometownObject[0].value;
        }
      }

      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      qs = `${qs}&$filter=Category eq '3'`;

      if (this.searchName) {
        qs = this.$appendSubstringofFilter("Name", qs, this.searchName);
      }
      if (this.searchId) {
        qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
      }
      if (this.searchGender) {
        qs = this.$appendSubstringofFilter("Gender", qs, this.searchGender);
      }
      if (
        Boolean(this.searchBirthday.year) &&
        Boolean(this.searchBirthday.month) &&
        Boolean(this.searchBirthday.day)
      ) {
        const date = `${this.searchBirthday.year}-${this.searchBirthday.month}-${this.searchBirthday.day}`;
        const startDate = moment(this.$usDate(date))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const endDate = this.$usDate(date);
        qs = this.$appendTimeFilter("BirthDate", qs, startDate, endDate);
      }
      if (this.searchSchool) {
        qs = this.$appendSubstringofFilter("SchoolCode", qs, this.searchSchool);
      }
      if (this.searchEntryYear) {
        qs = this.$appendSubstringofFilter(
          "StartYear",
          qs,
          this.searchEntryYear
        );
      }
      if (this.searchEndYear) {
        qs = this.$appendSubstringofFilter("EndYear", qs, this.searchEndYear);
      }
      if (this.searchMajor) {
        qs = this.$appendSubstringofFilter("MajorName", qs, this.searchMajor);
      }
      if (this.searchCategory) {
        qs = this.$appendSubstringofFilter("Country", qs, this.searchCategory);
      }
      if (this.searchHometown) {
        qs = this.$appendSubstringofFilter("County", qs, this.searchHometown);
      }
      if (this.searchStatus) {
        qs = this.$appendSubstringofFilter(
          "ServiceItem",
          qs,
          this.searchStatus
        );
      }
      if (this.searchServiceNow) {
        qs = this.$appendEqualFilter(
          "ServiceDetail",
          qs,
          this.searchServiceNow
        );
      }

      try {
        //console.log('GetStudentHead',`StudentInfo/GetStudentHead${qs}`);

        const { Items, Count } = await window.axios.get(
          `StudentInfo/GetStudentHead${qs}`
        );
        
        this.items = Items.map((s) => ({
          status: s.ServiceItem || "",
          servicenow: s.ServiceDetail || "",
          name: s.Name || "",
          id: s.Identifier || "",
          gender: !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女",
          birthday: s.Cht_BirthDate || "",
          school: this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "",
          entryYear: s.StartYear || "",
          graduateYear: s.EndYear || "",
          servicesys: s.ServiceSys || "",
          domicile: s.Country || "",
          hometown: this.hometownObject.find((k) => k.value == +s.County)
            ? this.hometownObject.find((k) => k.value == +s.County).text
            : "",

          contactAddress: s.ContAddress || "",
          homeAddress: s.ResidenceAddrs || "",
          telphone: s.Home || "",
          mobilephone: s.Mobile || "",
          email: s.Email || "",
          remark: Boolean(s.Note) ? s.Note.replace(/\n/g, "") : "",
          originRemark: s.Note || "",
          code: s.Code || "",
          majorYear: s.MajorYear || "",
          majorName: s.MajorName || "",
          originAllData: s,
          cat: s.MajorName || "",
          type: +s.Category || "",
          studentID: s.StudentID || "",
          serviceSystem: s.ServiceSystem || "",
          grade: s.Grade || "",
        }));

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
    async getHomeTown() {
      const arr = await window.axios.get("SysCode/Get?category=country");
      this.hometownObject = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));
      this.hometownObject.unshift({ value: null, text: "全部" });
    },
    async getMajor() {
      const arr = await window.axios.get("SysCode/Get?category=major");
      this.majorObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
      }));
      this.majorObject.unshift({ value: null, text: "全部" });
    },
    async getCountry() {
      const arr = await window.axios.get("SysCode/Get?category=hometown");
      this.countryObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
      }));
      this.countryObject.unshift({ value: null, text: "全部" });
    },
    async getAllHealth() {
      const arr1 = await window.axios.get(
        "SysRole/GetRoleInstitutions?no=user1"
      );
      const arr2 = await window.axios.get(
        "SysRole/GetRoleInstitutions?no=user4"
      );

      this.allHealthOption = [...arr1, ...arr2];
    },
  },
  async mounted() {
    await this.getAllHealth();
    await this.getAllSchool();
    await this.getHomeTown();
    await this.getCountry();
    await this.getMajor();
    await this.getData();
    this.$root.$on("get-Newest-LocalstuData", () => this.getData());
  },

  watch: {
    searchEntryYear(v) {
      if (+v < 0) {
        this.searchEntryYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEntryYear = result));
    },
    searchEndYear(v) {
      if (+v < 0) {
        this.searchEndYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEndYear = result));
    },
    "searchBirthday.year"(v) {
      if (+v < 0) {
        this.searchBirthday.year = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.year = result));
    },
    "searchBirthday.month"(v) {
      let value = v;
      if (+v < 0) {
        this.searchBirthday.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.month = result));
    },
    "searchBirthday.day"(v) {
      let value = v;
      if (+v < 0) {
        this.searchBirthday.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.day = result));
    },

    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    searchStatus(v) {
      if(v){
        this.serviceOption = this.allServiceObject[v];
      } else {
        this.serviceOption = [];
      }
      switch (v) {
        case "未入學":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option1;
          break;
        case "在學期間":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option2;
          break;
        case "畢業":
          this.searchServiceNow = "畢業";
          //this.serviceOption = this.allServiceObject.option3;
          break;
        case "訓練期間":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option4;
          break;
        case "服務期間":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option5;
          break;
        case "服務期滿":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option6;
          break;
        case "醫師證書":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option7;
          break;
        case "履約終止":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option8;
          break;
        case "展緩期間":
          this.searchServiceNow = null;
          //this.serviceOption = this.allServiceObject.option9;
          break;
        default:
          this.searchServiceNow = null;
          //this.serviceOption = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  width: 1815px;
  display: grid;
  grid-template-columns:
    repeat(1, 95px) 120px repeat(2, 220px) 120px repeat(2, 230px) repeat(1, 200px) repeat(3, 127px);
    /*repeat(5, 80px) repeat(9, 120px) repeat(2, 200px) 130px 150px
    160px
    380px;*/
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px max-content 356px repeat(30, max-content);
}

.dtc-search{
  grid-template-columns: 343px repeat(2, 200px) repeat(2, 218px);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  height: 145px;
  width: 1820px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
